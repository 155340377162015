<template>
  <v-toolbar flat class="mb-4">
    <v-toolbar-title>
      {{ title }}
      <span></span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }" v-if="permitted('Alarm.Create')">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            router
            to="/alarm/create"
            v-on="on"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("common.create") }}</span>
      </v-tooltip>
    </div>

    <v-dialog v-model="testDialog" presistent max-width="570">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon>mdi-settings</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <span class="headline">
            {{ $t("alarm.test.settings") }}
          </span>
        </v-card-title>

        <v-card-text>
          <v-text-field
            :label="$t('alarm.test.email')"
            name="email"
            prepend-icon="user"
            type="text"
            v-model="email"
          ></v-text-field>
        </v-card-text>

        <v-card-text>
          <phone-number-input
            v-on:validation="changeValidState"
            v-model="phonenumber"
          />
        </v-card-text>

        <v-card-text>
          <p v-for="r in response" v-bind:key="r">{{ r }}</p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="sendTest()"
            :disabled="!phoneNumberValid"
            >{{ $t("common.send") }}</v-btn
          >
          <v-btn color="grey" text @click="closeDialog()">{{
            $t("common.close")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "AlarmToolbar",
  props: {
    title: String,
  },
  data() {
    return {
      testDialog: false,
      phonenumber: "",
      email: "",
      response: [],
      phoneNumberValid: true,
    };
  },

  computed: {
    ...mapState("alarms", ["settingResponse"]),
    ...mapState("configuration", ["darkmode"]),
  },

  methods: {
    ...mapActions("alarms", ["testSettings", "clearTestResponse"]),

    async sendTest() {
      let payload = { email: this.email, phonenumber: this.phonenumber };

      await this.testSettings(payload);

      this.response.push(this.settingResponse.smsResponse);
      this.response.push(this.settingResponse.mailResponse);
    },

    closeDialog() {
      this.email = "";
      this.phonenumber = "";
      this.response = [];
      this.phoneNumberValid = true;
      this.clearTestResponse();
      this.testDialog = false;
    },

    changeValidState(val) {
      this.phoneNumberValid = val;
    },
  },
};
</script>
